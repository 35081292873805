import Component from "./Component";
import { resetFieldElementValidation } from "@/utils/utils";
export default class Input extends Component {
    constructor(el) {
        super(el);
        this.controlEvents = [
            "input",
            "keydown",
            "keyup",
            "change",
            "mousedown",
            "mouseup",
            "select",
            "contextmenu",
        ];
        this.controlType = el.getAttribute("data-slg-control-type");
        this.controlMaxLenght = parseInt(el.getAttribute("data-slg-control-max-length"));
        this.el.addEventListener("input", async (e) => {
            this.resetValidation();
        });
        this.oldValue = this.el.value;
        this.oldSelectionStart = this.el.selectionStart;
        this.oldSelectionEnd = this.el.selectionEnd;
        this.controlEvents.forEach((eventName) => {
            this.el.addEventListener(eventName, (e) => {
                if (!(this.checkControlType() && this.checkControlMaxLength())) {
                    if (this.oldValue.length < this.el.value.length)
                        this.el.value = this.oldValue;
                    // this.el.setSelectionRange(this.el.oldSelectionStart, this.el.oldSelectionEnd);
                }
                this.oldValue = this.el.value;
                this.oldSelectionStart = this.el.selectionStart;
                this.oldSelectionEnd = this.el.selectionEnd;
            });
        });
    }
    resetValidation() {
        resetFieldElementValidation(this.el);
    }
    isInvalid() {
        this.el.classList.add("is-invalid");
    }
    checkControlType() {
        if (this.controlType === "number")
            return /^\d*$/.test(this.el.value);
        if (this.controlType === "text")
            return /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]*$/g.test(this.el.value);
        return true;
    }
    checkControlMaxLength() {
        if (this.controlMaxLenght)
            return this.el.value.length <= this.controlMaxLenght;
        return true;
    }
}
Input.typeRef = "C-INPUT";
Input.tagRef = "INPUT";
