import { LeadgreaseSingleForm } from "./singleForm";
import Swiper from "swiper";
import "swiper/swiper.scss";
import {
	applyValidationStylesOnField,
	findParentElementByQuery,
} from "@/utils";
import {
	extractValuesFromMessage,
	getValueFromRadioGroupByName,
} from "@/utils/utils";
import { Birthday, FormSwiperSlide } from "@/modules/form/components/index";
import { getFieldsFromEl } from "@/utils/index";
import InstanceComponentsFromElUseCase from "@/modules/form/Application/UseCases/InstanceComponentsFromElUseCase";
import { resetFieldElementValidation } from "@/utils/utils";

export class LeadgreaseFormSwiper extends LeadgreaseSingleForm {
	constructor(el, config = {}, lgInstance) {
		super(el, config, lgInstance);

		let slides = this.getSlides();
		for (let i = 0; i < slides.length; i++) {
			const slide = slides[i];
			slide.style.display = "block";
			slide.setAttribute("data-lg-slide-index", i);
		}

		this.components = new InstanceComponentsFromElUseCase().run(
			this.el,
			this
		);
		// this.instanceComponents();
		this.resetValidation();
		this.checkChangeForm();
		this.swiper = new Swiper(el, {
			autoHeight: true,
			calculateHeight: true,
			spaceBetween: 20,
			allowTouchMove: false,
		});
	}
	init() {
		// this.checkAutocomplete();
		this.initQuestions();
		// this.initBirthday();
		// this.initControlInputs();
		this.autoPopulateQuery();
		this.initEventSubmit();
		this.initButtonBack();
		this.initSlidesAutoSubmit();
		this.updateProgressBarPercent();
	}

	saveDataForm(elementChange) {
		this.data[elementChange.name] = elementChange.value;
		// Hay que revisar si esta linea es realmente necesaria ejecutarla aqui por que esta ejecutando la validacion en todas las condiciones
		// de una menera inecesaria.
		this.validateConditions();
		// Esto tenemos que sacarlo por que esto modifica el swiper y no tiene nada que ver con el saveDatForm.
		this.swiper.update();
	}

	getSlideActive() {
		let components = this.components.filter((component) => {
			return (
				FormSwiperSlide.typeRef == component.type &&
				component.slideIndex == this.swiper.activeIndex
			);
		});
		return components[0];
	}

	async checkIfGoToNextSlide() {
		let slideActive = this.getSlideActive();
		let allConditionsValid = slideActive
			.validateConditions()
			.filter((condition) => condition == true);
		let fieldsNotInConditionalContainer =
			slideActive.checkFieldsNotInConditionalContainer();
		if (
			allConditionsValid.length == 0 &&
			fieldsNotInConditionalContainer.length == 0 &&
			!this.swiper.isEnd
		) {
			this.nextSlide();
		}
	}

	async checkIfGoToPrevSlide() {
		let components = this.components.filter((component) => {
			return (
				FormSwiperSlide.typeRef == component.type &&
				component.slideIndex == this.swiper.activeIndex
			);
		});
		let allConditionsValid = components[0]
			.validateConditions()
			.filter((condition) => condition == true);
		let fieldsNotInConditionalContainer =
			components[0].checkFieldsNotInConditionalContainer();
		if (
			allConditionsValid.length == 0 &&
			fieldsNotInConditionalContainer.length == 0 &&
			!this.swiper.isBeginning
		) {
			this.prevSlide();
		}
	}

	updateProgressBarPercent() {
		let progressBar = this.el.querySelector(
			"[data-lg-type='C-PROGRESS-BAR-PERCENT']"
		);
		if (!progressBar) return false;
		let progressBarPercent =
			this.el.querySelector(".progress-bar-percent") ||
			this.el.querySelector(".progress-bar");
		let progressBarTxt =
			this.el.querySelector(".progress-bar-txt") ||
			this.el.querySelector(".percent-txt");
		if (progressBarTxt && !progressBarTxt.hasAttribute("data-lg-init-txt"))
			progressBarTxt.setAttribute(
				"data-lg-init-txt",
				progressBarTxt.innerText
			);
		let percent = Math.round(
			(this.swiper.activeIndex / this.swiper.slides.length) * 100
		);
		progressBarPercent.style.width = percent + "%";
		let initTxt = progressBarTxt.getAttribute("data-lg-init-txt");
		progressBarTxt.innerText = initTxt.replace("{{percent}}", percent);
	}
	nextSlide() {
		this.exec("next-slide:start", { ...this });
		this.swiper.slideNext(500);
		this.exec("form-swiper:slider:change", { ...this });
		this.updateProgressBarPercent();
		this.checkIfGoToNextSlide();
		this.exec("next-slide:end", { ...this });
	}
	prevSlide() {
		this.exec("prev-slide:start", { ...this });
		this.swiper.slidePrev(500);
		this.exec("form-swiper:slider:change", { ...this });
		this.updateProgressBarPercent();
		this.checkIfGoToPrevSlide();
		this.exec("prev-slide:end", { ...this });
	}

	async slideTo(index) {
		if (this.swiper.activeIndex > index) {
			this.exec("prev-slide:start", { ...this });
		} else {
			this.exec("next-slide:start", { ...this });
		}

		this.swiper.slideTo(index, 500);
		await this.exec("form-swiper:slider:change", { ...this });
		this.updateProgressBarPercent();

		if (this.swiper.activeIndex > index) {
			this.checkIfGoToPrevSlide();
			this.exec("prev-slide:end", { ...this });
		} else {
			this.checkIfGoToNextSlide();
			this.exec("next-slide:end", { ...this });
		}
	}

	async getSlideData(index) {
		let slide = this.getSlide(index);
		let data = this.getArrayFromEl(slide);

		for (let i = 0; i < this.components.length; i++) {
			const component = this.components[i];
			if (
				component.type === Birthday.typeRef &&
				slide.contains(component.getEl())
			) {
				data = component.proxyData(data);
			}
		}

		return data;
	}

	async preValidateData() {
		let dataForm = await this.getData();
		let response = await this.apiCapture.captureValidation(dataForm);

		if (!response.success) {
			throw response;
		}

		return true;
	}

	initEventSubmit() {
		let slides = this.getSlides();
		for (let i = 0; i < slides.length; i++) {
			let slide = slides[i];
			let btns = slide.querySelectorAll("button[type='submit']");
			btns = Array.from(btns);
			if (btns.length == 0) continue;
			for (let j = 0; j < btns.length; j++) {
				const btn = btns[j];
				if (btn.hasAttribute("href")) {
					btn.removeAttribute("href");
				}
				if (i < slides.length - 1 && btn) {
					btn.addEventListener("click", async (e) => {
						e.preventDefault();
						let slideActive = this.getSlideActive();
						let validation = await super.checkValidateForm(
							slideActive.el
						);
						this.swiper.updateAutoHeight(10);
						// let slideData = await this.getSlideData(i);
						if (validation && validation.length == 0) {
							// this.disable();
							// let responseValidator = await this.apiCapture.captureValidation(slideData);

							// let { field } = extractValuesFromMessage(responseValidator.message);
							//if (field) {
							//    let fieldEl = this.el.querySelector(`[name=${field}]`);
							//    if (fieldEl) {
							//        applyValidationStylesOnField(fieldEl, false);
							//    }
							//}

							//this.active();

							//if (responseValidator.success === true) {
							this.nextSlide();
							// }
						}
					});
				} else {
					btn.addEventListener("click", async (e) => {
						e.preventDefault();
						await this.submit(e);
					});
				}
			}
		}
		return true;
	}

	async submit(event = null) {
		try {
			if (event) event.preventDefault();
			this.disable();
			this.loader.show();
			this.validation = await this.checkValidateForm();
			if (this.validation && this.validation.length === 0) {
				await this.sendData();
			} else {
				this.active();
				this.loader.hide();
			}
		} catch (error) {
			console.error(error);
			let { field } = extractValuesFromMessage(error.message);
			if (field) {
				if (field == "full_phone") field = "phone";
				let fieldEl = this.el.querySelector(`[name=${field}]`);
				if (fieldEl) {
					applyValidationStylesOnField(fieldEl, false);
					let slide = findParentElementByQuery(
						fieldEl,
						"[data-slg-type='C-FORM-SWIPER-SLIDE']"
					);
					if (slide) {
						await this.slideTo(
							parseInt(slide.getAttribute("data-lg-slide-index"))
						);
					}
				}
			}
			this.loader.hide();
			this.active();
		}
	}

	initSlidesAutoSubmit() {
		let slides = this.getSlides();
		for (let i = 0; i < slides.length; i++) {
			const slide = slides[i];
			this.initSlideAutoSubmit(slide);
		}
	}
	getSlides() {
		return this.el.querySelectorAll(
			"[data-slg-type='C-FORM-SWIPER-SLIDE']"
		);
	}

	getSlide(index) {
		let slides = this.getSlides();
		return slides[index];
	}

	/**
	 * @description Esta función se encarga de que si un layer tiene solo campos seleccionables (select, radio, checkbox) y todos los datos ya estan seleccionados, entonces se pasa al siguiente layer.
	 */
	async initSlideAutoSubmit(slide) {
		let fields = this.getFieldsFromForm(slide);
		// let slideIndex = slide.getAttribute('data-lg-slide-index');
		let findSelectableFields = fields.filter(
			(field) =>
				field.tagName == "SELECT" ||
				(field.tagName == "INPUT" && field.type == "radio")
		);
		if (fields.length === findSelectableFields.length) {
			for (let i = 0; i < fields.length; i++) {
				const field = fields[i];
				field.addEventListener("change", async (e) => {
					let fieldsSlide = getFieldsFromEl(slide);
					let fieldsSlideValidated = fieldsSlide.filter((field) => {
						return field.classList.contains("is-invalid");
					});
					let fieldsSlideNotValidated = fieldsSlide.filter(
						(el) => !fieldsSlideValidated.includes(el)
					);

					let validation = await this.checkValidateForm(slide);
					if (validation && validation.length == 0) {
						if (
							slide.nextElementSibling &&
							slide.classList.contains("swiper-slide-active")
						) {
							this.nextSlide();
						}
					} else {
						// Se tiene que restablecer la validacion de esos elementos que no fueron previamente validados.
						// Y del elemento seleccionado, pero ojo esta parte se puede llevar al elemento select base
						fieldsSlideNotValidated.forEach((field) => {
							resetFieldElementValidation(field);
						});
					}
				});
				if (field.id) {
					const fieldLabel = field.parentNode.querySelector(
						`[for=${field.id}]`
					);
					if (fieldLabel) {
						fieldLabel.addEventListener("click", async (e) => {
							// Cuando se hace click en el label de un elemento input > radio nos interesa que el comportamiento change se vuelva a ejecutar.
							// De esta forma todos el comportamiento que se ejecuta cuando hay un change de un campo se ejecuta al hacer click en la label
							// Que no sucede cuando hacer click en un label de un elemento previamente seleccionado.
							// Esto realmente solo afecta cuando tenemos un formulario de varios pasos en un formulario de un solo paso no deberia ser un problema.

							const fieldName = field.name;
							const radioGroupValue =
								getValueFromRadioGroupByName(fieldName);
							if (field.value == radioGroupValue) {
								var eventChange = new Event("change");
								field.dispatchEvent(eventChange);
							}
						});
					}
				}
			}
		}
	}
	initButtonBack() {
		let backBtns = this.el.querySelectorAll("[href='@back']");
		if (!backBtns) return false;
		for (let i = 0; i < backBtns.length; i++) {
			const backBtn = backBtns[i];
			backBtn.addEventListener("click", (e) => {
				e.preventDefault();
				let slide = backBtn.closest(
					"[data-slg-type='C-FORM-SWIPER-SLIDE']"
				);
				let prevSlide = slide.previousElementSibling;
				if (prevSlide) {
					this.prevSlide();
				}
			});
		}
		return true;
	}

	disable() {
		/* When send data disable button sumbit */
		let buttons = this.el.querySelectorAll("[type=submit]");
		if (buttons && buttons.length > 0) {
			for (let x = 0; x < buttons.length; x++) {
				const button = buttons[x];
				button.classList.add("disabled");
				button.disabled = true;
			}
		}
	}

	active() {
		/* When send data disable button sumbit */
		let buttons = this.el.querySelectorAll("[type=submit]");
		if (buttons && buttons.length > 0) {
			for (let x = 0; x < buttons.length; x++) {
				const button = buttons[x];
				button.classList.remove("disabled");
				button.disabled = false;
			}
		}
	}
}
