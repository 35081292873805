function extractValuesFromMessage(message) {
    // Utilizamos una expresión regular para encontrar todos los valores entre comillas
    const matches = message.match(/'([^']*)'/g);
    let result = {
        field: null,
        value: null,
        reason: null,
    };
    if (matches && matches.length == 3) {
        const field = matches[0].replace(/'/g, "");
        const value = matches[1].replace(/'/g, "");
        const reason = matches.length >= 3 ? matches[2].replace(/'/g, "") : null;
        result = {
            field,
            value,
            reason,
        };
    }
    else if (matches && matches.length >= 4) {
        const field = matches[1].replace(/'/g, "");
        const value = matches[2].replace(/'/g, "");
        result = {
            field,
            value,
            reason: message,
        };
    }
    return result;
}
function showElement(el) {
    el.style.display = "block";
    el.style.visibility = "visible";
}
function hideElement(el) {
    el.style.setProperty("display", "none", "important");
    el.style.visibility = "hidden";
}
function getValueFromRadioGroupByName(name) {
    const radios = document.querySelectorAll(`input[type="radio"][name="${name}"]`);
    for (const radio of radios) {
        if (radio.checked) {
            return radio.value;
        }
    }
    return null;
}
function resetFieldElementValidation(el) {
    el.classList.remove("is-invalid");
    el.parentElement.classList.remove("bg-warning");
}
export { extractValuesFromMessage, showElement, hideElement, getValueFromRadioGroupByName, resetFieldElementValidation };
