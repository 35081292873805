import Component from "./Component";
export default class ApplicationCoregistrationRequestButton extends Component {
    build({ lgInstance }) {
        this.lgInstance = lgInstance;
        this.textAfterRequest = this.el.getAttribute("data-lg-text-after-request");
        return this;
    }
    requested() {
        this.el.classList.replace("btn-primary", "btn-outline-primary");
        this.el.setAttribute("disabled", "");
        if (this.textAfterRequest) {
            this.el.innerText = this.textAfterRequest;
        }
    }
}
ApplicationCoregistrationRequestButton.typeRef = "C-APPLICATION-COREGISTRATION-REQUEST-BUTTON";
