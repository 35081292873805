import { LeadgreaseApiCapture } from "@/modules/api/index";
export default class LeadgreaseEndpointsV2 {
    constructor(accountCode) {
        this.accountCode = accountCode;
        this.endpoints = {
            [LeadgreaseApiCapture.ModeProduction]: {
                capture: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture",
                update: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/update/",
                response: "https://eu1-platform.leadgrease.com/5/capture/response",
                validation: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/validation",
                sponsorList: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/sponsors",
                captureCoregistration: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/campaigns/{{uuid}}/send/{{leadId}}",
                captureHistory: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/{{leadId}}/history"
            },
            [LeadgreaseApiCapture.ModePreview]: {
                capture: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture",
                update: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/update/",
                response: "https://eu1-platform.leadgrease.com/5/capture/response",
                validation: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/validation",
                sponsorList: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/sponsors",
                captureCoregistration: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/campaigns/{{uuid}}/send/{{leadId}}",
                captureHistory: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/{{leadId}}/history"
            },
            [LeadgreaseApiCapture.ModeDemo]: {
                capture: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture",
                update: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/update/",
                response: "https://eu1-platform.leadgrease.com/5/capture/response",
                validation: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/validation",
                sponsorList: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/sponsors",
                captureCoregistration: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/campaigns/{{uuid}}/send/{{leadId}}",
                captureHistory: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/{{leadId}}/history"
            },
            prod: {
                capture: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture",
                update: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/update/",
                response: "https://eu1-platform.leadgrease.com/5/capture/response",
                validation: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/validation",
                sponsorList: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/sponsors",
                captureCoregistration: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/campaigns/{{uuid}}/send/{{leadId}}",
                captureHistory: "https://eu1-forms.leadgrease.com/" + this.accountCode + "/capture/{{leadId}}/history"
            },
            preproduction: {
                capture: "https://eu1-capture-pre.leadgrease.com/" + this.accountCode + "/capture",
                update: "https://eu1-capture-pre.leadgrease.com/" + this.accountCode + "/capture/update",
                response: "https://eu1-capture-pre.leadgrease.com/" + this.accountCode + "/capture/response",
                validation: "https://eu1-capture-pre.leadgrease.com/" + this.accountCode + "/capture/validation",
                sponsorList: "https://eu1-capture-pre.leadgrease.com/" + this.accountCode + "/capture/sponsors",
                captureCoregistration: "https://eu1-capture-pre.leadgrease.com/" + this.accountCode + "/capture/campaigns/{{uuid}}/send/{{leadId}}",
                captureHistory: "https://eu1-capture-pre.leadgrease.com/" + this.accountCode + "/capture/{{leadId}}/history"
            },
            dev: {
                capture: "http://localhost:8015/capture",
                update: "http://localhost:8015/capture/update/",
                response: "http://localhost:8003/capture/response",
                validation: "http://localhost:8015/capture/validation",
                sponsorList: "http://localhost:8015/capture/sponsors",
                captureCoregistration: "http://localhost:8015/capture/campaigns/{{uuid}}/send/{{leadId}}",
                captureHistory: "http://localhost:8015/capture/{{leadId}}/history"
            }
        };
    }
    getEndpoint(mode) {
        return this.endpoints[mode];
    }
}
