import { ComponentModules } from "@/modules/form/components/index";
export default class InstanceComponentsFromElUseCase {
    run(el, form, apiCapture) {
        let components = [];
        let items = el.querySelectorAll("[data-lg-type],[data-slg-type],select,input");
        for (const ModuleName in ComponentModules) {
            if (Object.hasOwnProperty.call(ComponentModules, ModuleName)) {
                const Module = ComponentModules[ModuleName];
                items.forEach((item) => {
                    if (item.getAttribute("data-lg-type") == Module.typeRef ||
                        item.getAttribute("data-slg-type") == Module.typeRef ||
                        item.tagName == Module.tagRef) {
                        components.push(new Module(item, form).build({ apiCapture }));
                    }
                });
            }
        }
        return components;
    }
}
