import { LeadgreaseSingleForm } from "./singleForm";

export class LeadgreaseLayerForm extends LeadgreaseSingleForm {
    constructor(el, config = {}, lgInstance) {

        super(el, config, lgInstance);
        this.layers = this.el.querySelectorAll("[data-slg-type='C-LAYER']") || [];

    }

    init() {
        this.initQuestions();
        this.initBirthday();
        this.initSelect();
        //this.initControlInputs();
        this.autoPopulateQuery();
        this.initEventSubmit();
        this.initButtonBack();
        this.initLayersAutoSubmit();
    }
    initEventSubmit() {
        for (let i = 0; i < this.layers.length; i++) {
            const layer = this.layers[i];
            let btn = layer.querySelector("button[type='submit']");

            if (!btn) return false;

            if (btn.hasAttribute("href")) {
                btn.removeAttribute("href");
            }
            if (i < this.layers.length - 1 && btn) {
                const nextLayer = this.layers[i + 1];

                btn.addEventListener("click", async (e) => {
                    e.preventDefault();
                    let validation = await super.checkValidateForm(layer);
                    if (validation && validation.length == 0) {
                        layer.style.display = "none";
                        nextLayer.style.display = "block";
                    }

                });
            } else {
                btn.addEventListener("click", async (e) => {
                    e.preventDefault();
                    await this.submit(e);
                })
            }
        }
        return true;
    }
    initLayersAutoSubmit() {
        for (let i = 0; i < this.layers.length; i++) {
            const layer = this.layers[i];
            this.initLayerAutoSubmit(layer);
        }
    }
    /**
     * @description Esta función se encarga de que si un layer tiene solo campos seleccionables (select, radio, checkbox) y todos los datos ya estan seleccionados, entonces se pasa al siguiente layer.
     */
    async initLayerAutoSubmit(layer) {
        let fields = this.getFieldsFromForm(layer);
        let findSelectableFields = fields.filter(field => field.tagName == "SELECT" || (field.tagName == "INPUT" && field.type == "radio"));
        if (fields.length === findSelectableFields.length) {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                field.addEventListener("change", async (e) => {
                    let validation = await this.checkValidateForm(layer);
                    if (validation && validation.length == 0) {
                        if (layer.nextElementSibling) {
                            layer.style.display = "none";
                            layer.nextElementSibling.style.display = "block";
                        }

                    } else {
                        this.resetValidation(layer);
                    }
                });
            }
        }

    }
    initButtonBack() {
        let backBtns = this.el.querySelectorAll("[data-slg-type='C-LAYER-BACK-BUTTON'], [href='@back']");
        if (!backBtns) return false;
        for (let i = 0; i < backBtns.length; i++) {
            const backBtn = backBtns[i];
            backBtn.addEventListener("click", (e) => {
                e.preventDefault();
                let layer = backBtn.closest("[data-slg-type='C-LAYER']");
                let prevLayer = layer.previousElementSibling;
                if (prevLayer) {
                    layer.style.display = "none";
                    prevLayer.style.display = "block";
                }

            });
        }
        return true;
    }




}