import Component from "./Component";
import InstanceComponentsFromElUseCase from "../Application/UseCases/InstanceComponentsFromElUseCase";
import { LeadgreaseApiCapture } from "@/modules/api";
import ApplicationCoregistrationRequestButton from "./ApplicationCoregistrationRequestButton";
export default class ApplicationCoregistration extends Component {
    constructor(el) {
        super(el);
        this.components = [];
        this.timeRedirect = 3000;
        this.redirectTarget = "_self";
    }
    build({ lgInstance }) {
        this.lgInstance = lgInstance;
        this.apiCapture = lgInstance.ApiCapture;
        this.loader = lgInstance.Loader;
        this.components = new InstanceComponentsFromElUseCase().run(this.el, null, this.apiCapture);
        // action-after-complete-request
        this.actionAfterCompleteRequest = this.el.getAttribute("data-lg-action-after-complete-request");
        this.redirectTarget = this.el.getAttribute("data-lg-redirect-target");
        this.redirectUrl = this.el.getAttribute("data-lg-redirect-url");
        this.campaignUuid = this.el.getAttribute("data-lg-campaign-uuid");
        if (this.el.hasAttribute("data-lg-time-redirect"))
            this.timeRedirect = this.el.getAttribute("data-lg-time-redirect");
        return this;
    }
    init() {
        this.initRequestButton();
        return this;
    }
    initRequestButton() {
        let requestBtns = this.components.filter((component) => {
            return (component.type == ApplicationCoregistrationRequestButton.typeRef);
        });
        requestBtns.forEach((item) => {
            let requestBtnsEl = item.getEl();
            let findRequestInDeliveryHistory = this.lgInstance.CaptureHistory.DeliveryHistory.find((item) => {
                return this.campaignUuid == item.uuid;
            });
            if (findRequestInDeliveryHistory) {
                item.requested();
            }
            requestBtnsEl.addEventListener("click", async (e) => {
                e.preventDefault();
                item.requested();
                this.loader.show();
                if ((this.apiCapture.mode ===
                    LeadgreaseApiCapture.ModeProduction ||
                    this.apiCapture.mode ===
                        LeadgreaseApiCapture.ModePreProduction ||
                    this.apiCapture.mode ===
                        LeadgreaseApiCapture.DevMode) &&
                    this.lgInstance.queryParams.leadId &&
                    this.campaignUuid) {
                    let response = await this.apiCapture.captureCoregistration(this.lgInstance.queryParams.leadId, this.campaignUuid);
                }
                if (this.redirectUrl && this.timeRedirect) {
                    setTimeout(() => {
                        this.lgInstance.redirect(this.redirectUrl, {}, this.redirectTarget);
                        this.loader.hide();
                    }, this.timeRedirect);
                }
                else {
                    this.loader.hide();
                }
            });
        });
    }
}
ApplicationCoregistration.typeRef = "C-APPLICATION-COREGISTRATION";
ApplicationCoregistration.ActionAfterCompleteRequestTypeRedirect = "redirect";
