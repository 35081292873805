/****
 *  Usage
 *  var lgConfig= {
 *      appId: "Account ID",
 *      apiKey: "Provider Key",
 *      <optional> form: "Form Key",
 *
 *  }
 * var lgCustom= {
 *     onResponse: function(){
 *          // Action
 *     }
 * }
 *
 * var stepsConfig = {
 *      "countSteps": "Total steps",
 *      "currentStep": " Current step"
 * };
 * @param Config
 * @returns {{formKey: (*|string)}}
 */

import { Loader } from "@/modules/loader/index";
import { LeadgreaseSingleForm } from "@/modules/form/singleForm";
import { LeadgreaseLayerForm } from "@/modules/form/layerForm";
import { LeadgreaseFormSwiper } from "@/modules/form/formSwiper";
import { LeadgreaseEndpointsV2 } from "@/modules/api/endpoints/index";
import { LeadgreaseEndpointsV1 } from "@/modules/api/endpoints/index";
import "bootstrap";
import InstanceComponentFromElUseCase from "./modules/form/Application/UseCases/InstanceComponentFromElUseCase";
import InstanceComponentsFromElsUseCase from "./modules/form/Application/UseCases/InstanceComponentsFromElsUseCase";
import { ApplicationCoregistration } from "@/modules/form/components/index";
import { LeadgreaseAuthentication } from "./modules/authentication";
import { LeadgreaseApiCapture } from "./modules/api";

export class Leadgrease {
	constructor({
		lgConfig,
		lgCustom,
		lgPixels,
		stepsConfig,
		apiVersion,
		accountCode,
	}) {
		console.log("Leadgrease: Building");

		this.AfterCompletionNothing = 0;
		this.AfterCompletionRedirect = 1;
		this.AfterCompletionThankyouPage = 2;

		this.lgConfig = lgConfig;
		this.appId = this.lgConfig.appId;
		this.isDev = this.lgConfig.isDev;
		this.lgCustom = lgCustom;
		this.lgPixels = lgPixels;
		this.stepsConfig = stepsConfig;
		this.Loader = new Loader();
		this.apiVersion = apiVersion;
		this.EndPoints =
			apiVersion != 2
				? new LeadgreaseEndpointsV1()
				: new LeadgreaseEndpointsV2(accountCode);

		this.Authentication = new LeadgreaseAuthentication(this.lgConfig);
		this.ApiCapture = new LeadgreaseApiCapture(
			this.Authentication,
			this.lgConfig.mode,
			this.isDev,
			this.EndPoints
		);

		this.instanceComponentFromElUseCase =
			new InstanceComponentFromElUseCase();
		this.instanceComponentsFromElsUseCase =
			new InstanceComponentsFromElsUseCase(
				this.instanceComponentFromElUseCase
			);

		this.Forms = [];
		this.Form = null;
		this.Components = [];
		this.CaptureHistory = {
			DeliveryHistory: [],
		};
		this.queryParams = this.getQueryParamsFromUrl(location);
		this.timeRedirect = 3000;

		this.init();
	}

	async init() {
		console.log("Leadgrease: Starting");
		this.Loader.show();
		this.autocomplete();

		let form = document.querySelector("form");
		if (form)
			this.Form = await this.initForm(form, {
				...this.getConfiguration(),
				apiKey: this.apiKey,
				formKey: this.formKey,
			});

		// Tenemos un problema y es que los componente se estan instaniado dos veces y eso duplica los comportamientos.
		// De momento solo instanciaremos este componente especifico para evitar duplicidad de eventos en los componentes.
		// Hay que buscar una manera de que el componente solo se instancie una vez y se vaya reutilizando la misma instancia.

		let elements = document.querySelectorAll(
			"[data-lg-type=C-APPLICATION-COREGISTRATION]"
		);

		this.Components = this.instanceComponentsFromElsUseCase.run(
			Array.from(elements),
			this.Form,
			this
		);

		await this.loadCaptureHistory();

		this.Components.forEach((component) => {
			component.build({ lgInstance: this });
		});

		this.Components.forEach((component) => {
			component.init();
		});

		this.Loader.hide();

		this.redirectWhenDontHaveForm();
	}

	async initForm(el, config) {
		let lgForm;
		if (el.getAttribute("data-slg-type") == "C-FORM-LAYERS") {
			lgForm = new LeadgreaseLayerForm(el, config, this);
		} else if (el.getAttribute("data-slg-type") == "C-FORM-SWIPER") {
			lgForm = new LeadgreaseFormSwiper(el, config, this);
		} else {
			lgForm = new LeadgreaseSingleForm(el, config, this);
		}
		await lgForm.init();
		this.Forms.push(lgForm);
		return lgForm;
	}

	getConfiguration() {
		return {
			lgConfig: this.lgConfig,
			lgCustom: this.lgCustom,
			lgPixels: this.lgPixels,
			stepsConfig: this.stepsConfig,
		};
	}

	autocomplete() {
		let textAutocomplete = document.querySelectorAll(
			"[data-lg-autocomplete]"
		);
		Array.from(textAutocomplete).forEach((element) => {
			for (const key in this.queryParams) {
				element.innerHTML = element.innerHTML.replace(
					`{{${key}}}`,
					this.queryParams[key]
				);
			}
		});
	}

	hasComponentApplicationCoregistrationOnPage() {
		return (
			this.Components.filter((component) => {
				return ApplicationCoregistration.typeRef == component.type;
			}).length > 0
		);
	}

	async loadCaptureHistory() {
		if (
			(this.ApiCapture.mode === LeadgreaseApiCapture.ModeProduction ||
				this.ApiCapture.mode ===
					LeadgreaseApiCapture.ModePreProduction ||
				this.ApiCapture.mode === LeadgreaseApiCapture.DevMode) &&
			this.hasComponentApplicationCoregistrationOnPage() &&
			this.queryParams.leadId
		) {
			let responseCaptureHistory =
				await this.ApiCapture.getCaptureHistory(
					this.queryParams.leadId
				);

			this.CaptureHistory.DeliveryHistory =
				responseCaptureHistory.deliveryHistory;
		}
	}

	redirectWhenDontHaveForm() {
		// console.log(this.hasComponentApplicationCoregistrationOnPage());
		if (
			((!this.Form && this.Forms.length == 0) ||
				this.Form.hasFields() == false) &&
			!this.hasComponentApplicationCoregistrationOnPage() &&
			this.AfterCompletionRedirect == this.lgConfig.afterCompletion &&
			this.lgConfig.redirectUrl
		) {
			// console.log("Se debio ejecutar la redirección");
			setTimeout(() => {
				this.redirect(this.lgConfig.redirectUrl);
			}, this.timeRedirect);
		}
	}

	getQueryParamsFromUrl(url) {
		let parseUrl = new URL(url);
		return Object.fromEntries(new URLSearchParams(parseUrl.search));
	}
	getUtmQueryParams() {
		let utmParams = {};
		for (let param in this.queryParams) {
			if (param.includes("utm")) {
				utmParams[param] = this.queryParams[param];
			}
		}
		return utmParams;
	}
	handleQueryParams(oldQueryParams, newQueryParams) {
		for (let newParam in newQueryParams) {
			for (let oldParam in oldQueryParams) {
				if (`{{${oldParam}}}` == newQueryParams[newParam]) {
					newQueryParams[newParam] = oldQueryParams[oldParam];
				}
			}
		}

		return newQueryParams;
	}
	redirect(url, queryParams = {}, target = "_self") {
		// if(!queryParams) queryParams = {};

		let redirectQueryParams = this.getQueryParamsFromUrl(url);
		let completeRedirectQueryParams = this.handleQueryParams(
			this.queryParams,
			redirectQueryParams
		);
		let utmQueryParams = this.getUtmQueryParams();
		queryParams = {
			...completeRedirectQueryParams,
			...queryParams,
			...utmQueryParams,
		};
		if (target == "_blank") {
			let tagATemp = document.createElement("a");
			tagATemp.setAttribute("target", target);
			tagATemp.setAttribute("href", url);
			console.log(tagATemp);
			tagATemp.click();
		} else {
			let newUrl = new URL(url);
			newUrl.search = new URLSearchParams(queryParams).toString();
			window.location.assign(newUrl);
		}
	}
}
