export default class Component {
    constructor(el) {
        if (el instanceof HTMLElement) {
            this.el = el;
        }
        else {
            this.el = document.querySelector(el);
        }
        this.id = this.el.id;
        this.type =
            this.el.getAttribute("data-lg-type") ||
                this.el.getAttribute("data-slg-type");
    }
    build({}) {
        return this;
    }
    init() {
        return this;
    }
    show() {
        if (this.el) {
            this.el.style.display = "block";
        }
    }
    hide() {
        if (this.el)
            this.el.style.setProperty("display", "none", "important");
    }
    getEl() {
        return this.el;
    }
}
Component.typeRef = "C-COMPONENT";
Component.tagRef = null;
