export default class InstanceComponentsFromElsUseCase {
    constructor(instanceComponentFromElUseCase) {
        this.instanceComponentFromElUseCase = instanceComponentFromElUseCase;
    }
    run(elements, form, lgInstance) {
        return elements
            .map((item) => {
            return this.instanceComponentFromElUseCase.run(item, form, lgInstance);
        })
            .filter((item) => item != null);
    }
}
