import ContainerConditional from '@/modules/form/components/ContainerConditional';
import Select from '@/modules/form/components/Select';
import Input from '@/modules/form/components/Input';
import Birthday from '@/modules/form/components/Birthday';
import GoogleMapsAddress from '@/modules/form/components/GoogleMapsAddress';
import WrongAddressMessage from '@/modules/form/components/WrongAddressMessage';
import FormSwiperSlide from '@/modules/form/components/FormSwiperSlide';
import ApplicationCoregistrationRequestButton from '@/modules/form/components/ApplicationCoregistrationRequestButton';
import ApplicationCoregistration from '@/modules/form/components/ApplicationCoregistration';
const ComponentModules = [
    Birthday,
    Select,
    Input,
    ContainerConditional,
    WrongAddressMessage,
    GoogleMapsAddress,
    FormSwiperSlide,
    ApplicationCoregistrationRequestButton,
    ApplicationCoregistration,
];
export { WrongAddressMessage, ContainerConditional, GoogleMapsAddress, Birthday, Select, Input, FormSwiperSlide, ApplicationCoregistrationRequestButton, ApplicationCoregistration, ComponentModules };
