import { ComponentModules } from "@/modules/form/components/index";
export default class InstanceComponentFromElUseCase {
    run(el, form, lgInstance) {
        for (const ModuleName in ComponentModules) {
            if (Object.hasOwnProperty.call(ComponentModules, ModuleName)) {
                const Module = ComponentModules[ModuleName];
                if (el.getAttribute("data-lg-type") == Module.typeRef ||
                    el.getAttribute("data-slg-type") == Module.typeRef ||
                    el.tagName == Module.tagRef) {
                    return new Module(el, form);
                }
            }
        }
        return null;
    }
}
